const clients = [
  'andresmaleri',
  'dryft',
  'ibexbygg',
  'jacobsenbygg',
  'juper',
  'koncepthem',
  'snickarkraft',
  'takeliten',

]

export default clients